<template>
    <div>
        <VicariusLanging :language = "dominioactual" />
    </div>
</template>

<script>
import VicariusLanging from './VicariusLanding.vue'

export default {
  components: {
    VicariusLanging
  },
  data () {
    return {
      dominioactual: navigator.language
    }
  },
  created () {
    this.track()
    console.log('Esto se ve en Vicarius')
    console.log(navigator.language)
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/solutions/cybersecurity/vicarius' })
    }
  }
}
</script>

<style>

</style>
